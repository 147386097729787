








import Vue from 'vue';
import store from '@/scripts/store';
import { INavigationExtensionTabs } from '@/scripts/store/modules/navigation/types';

export default Vue.extend({
  name: 'community-tabs',
  beforeRouteEnter(to, from, next) {
    const tabs: INavigationExtensionTabs[] = [
      {
        id: 'topics',
        icon: 'mdi-forum',
        title: 'app.community.extended_tabs.topics',
        route: 'community-topics',
      },
      {
        id: 'comments',
        icon: 'mdi-message-text',
        title: 'app.community.extended_tabs.my_posts',
        badge: true,
        route: 'community-comments',
      },
    ];
    store.commit('navigation/setExtensionTabs', tabs);
    next();
  },
  beforeRouteLeave(to, from, next) {
    store.commit('navigation/removeExtensionTabs');
    next();
  },
});
